import {
    Livewire,
    Alpine,
} from "../../vendor/livewire/livewire/dist/livewire.esm";

// Plugins
import ui from "@alpinejs/ui";

Alpine.plugin(ui);

Livewire.start();
